var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-row',[_c('a-col',{attrs:{"span":22}},[_c('a-form-model',{attrs:{"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{attrs:{"placeholder":"任务名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"160px"},attrs:{"placeholder":"任务状态","show-search":""},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.statusList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1)],1),_c('a-col',{attrs:{"span":2}},[_c('div',{staticClass:"right",staticStyle:{"margin-top":"4px"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push(_vm.$route.path + '/select')}}},[_vm._v("创建任务")])],1)])],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"任务名称"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goDetail(text)}}},[_vm._v(_vm._s(text.name))])]}}])}),_c('a-table-column',{attrs:{"title":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"bim_state_code","dictValue":text.stateCode}})]}}])}),_c('a-table-column',{attrs:{"title":"创建时间","data-index":"createAt","align":"center"}}),_c('a-table-column',{attrs:{"title":"进度","align":"center","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-progress',{attrs:{"percent":typeof text.progress === 'number' ? text.progress : 0}})]}}])}),_c('a-table-column',{attrs:{"title":"状态","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"bim_task_status","dictValue":text.status}})]}}])}),_c('a-table-column',{attrs:{"title":"操作","align":"right","width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.goDetail(text)}}},[_vm._v("查看")]),(text.previewFile)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.preview(text)}}},[_vm._v("预览")]):_vm._e(),(text.bimFile)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.download(text)}}},[_vm._v("下载")]):_vm._e(),(
                text.status !== 'deleted' &&
                text.status !== 'cancel' &&
                text.status !== 'finished'
              )?_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.killText(text)}}},[_vm._v("停止")]):_vm._e()])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="任务名称" />
            </a-form-model-item>
            <a-form-model-item>
              <a-select
                placeholder="任务状态"
                v-model="form.status"
                show-search
                style="width: 160px"
              >
                <a-select-option
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button
              @click="$router.push($route.path + '/select')"
              type="primary"
              >创建任务</a-button
            >
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="任务名称">
          <template slot-scope="text">
            <a href="#" @click.prevent="goDetail(text)">{{ text.name }}</a>
          </template>
        </a-table-column>

        <a-table-column title="状态">
          <template slot-scope="text">
            <DataDictFinder
              dictType="bim_state_code"
              :dictValue="text.stateCode"
            />
          </template>
        </a-table-column>

        <a-table-column
          title="创建时间"
          data-index="createAt"
          align="center"
        ></a-table-column>

        <a-table-column title="进度" align="center" width="200px">
          <template slot-scope="text">
            <a-progress
              :percent="typeof text.progress === 'number' ? text.progress : 0"
            />
          </template>
        </a-table-column>

        <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="bim_task_status"
              :dictValue="text.status"
            />
          </template>
        </a-table-column>

        <a-table-column title="操作" align="right" width="140px">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="goDetail(text)">查看</a>
              <a href="#" @click.prevent="preview(text)" v-if="text.previewFile"
                >预览</a
              >
              <a href="#" @click.prevent="download(text)" v-if="text.bimFile"
                >下载</a
              >
              <a
                href="#"
                class="danger"
                @click.prevent="killText(text)"
                v-if="
                  text.status !== 'deleted' &&
                  text.status !== 'cancel' &&
                  text.status !== 'finished'
                "
                >停止</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { mapGetters } from "vuex";
import { fetchList, kill } from "@/api/parameterization/task";
export default {
  mixins: [watermark],
  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("bim_task_status");
    },
  },

  mounted() {
    this.getList();

    this.timer = setInterval(() => {
      this.getList();
    }, 10000);
    this.$once("hook:beforeDestroy", () => {
      if (this.timer) {
        console.log("清空计时器");
        clearInterval(this.timer);
      }
    });
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    goDetail(text) {
      this.$router.push(this.$route.path + "/detail?id=" + text.id);
    },
    download(text) {
      window.open(text.bimFile);
    },

    preview(text) {
      window.open("https://app.njszy.com/three.html?file=" + text.previewFile);
      // window.open("http://localhost:8082/three.html?file=" + text.previewFile);
    },

    killText(text) {
      const that = this;
      this.$confirm({
        title: "确认要结束任务吗？",
        onOk() {
          kill({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>


<style lang="less" scoped>
.search-result {
  padding: 24px 12px 12px;

  .item {
    cursor: pointer;
    color: #1890ff;
    margin-bottom: 8px;
  }
}
</style>